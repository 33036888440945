import addressComp from "../address-comp.vue";
import commonApiService from "../../common/js/common-api-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
//import salesService from '../../sales-order/js/sales-order-service';
import moment from "moment";
import BackToTop from "../../common/back-to-top.vue";
import commonPoService from "./common-po-service";
import Utility from "../../../shared/utility";
export default {
  name: "purchaseOrderAdd",
  data() {
    return {
      stepElement: 1,
      //
      //Search Keys
      venSearchKey: "",
      receiveSearchKey: "",
      remitSearchKey: "",
      partReturnSearchKey: "",
      partShipSearchKey: "",
      //BreadCrumb Data
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: "",
      subPageUrl: "",
      appPageName: "",
      route: "",
      backSlashParent: true,
      showFlex:false,
      backSlash: true,
      showOrderedDate:true,
      showDeliveryDate:true,
      showTempShipByDate:true,
      showTempDeliveryDate:true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      vendorList: [],
      remitList: [],
      receiveList: [],
      businessList: [],
      orderTypeList: [],
      paymentList: [],
      LineTypeList: [],
      returnResultList: [],
      projectList: [],
      partResultList: [],
      shipWareList: [],
      shipCarrierList: [],
      returnCarrierList: [],
      returnWareList: [],
      warrantyList: [],
      receiveCosmeticList: [],
      shipCosmeticList: [],
      totalRecords: 0,
      temp_waybill_id: 1,
      panel: [0, 1, 2, 3, 4],
      returnCount: 0,
      shipCount: 0,
      //disables flags
      disableVendor: false,
      showVenAddress: false,
      disableEmitTo: false,
      disableReceiveAdd: false,
      disableEmitAdd: false,
      disablePurchaseForm: true,
      showEditVendor: false,
      projectName: "",
      businessName: "",
      sameAsVendorCheck: false,
      disableBusiness: false,
      sameAsShip: false,
      showEmitDDL: false,
      showReceivedDDL: false,
      showAddressDialog: false,
      disableQty: false,
      showEditRemit: false,
      disableShippingSection: false,
      showFlexDialog: false,
      showLineDelete: false,
      showUpdateLine: false,
      deleteDialog: false,
      deleteFlexItem: false,
      disableNotes: false,
      deleteLine: false,
      showLineItem: false,
      disableLine: false,
      showPartRetDetails: false,
      showexpIcon: false,
      showLineUpdate: false,
      showPartsItem: false,
      showPartData: false,
      showExpectedSr: false,
      diableShipQty: false,
      disableOrderType: true,
      showSerialNum: false,
      disablePartShip: false,
      vendorDisable: true,
      shippingReq: false,
      returnReq: false,
      notRequired: false,
      disableSignature: false,
      disableSatDel: false,
      carrierLevlInfo: false,
      disableAuto: true,
      // Purchase Order Array
      purchaseOrderJson: {
        proj_id: "",
        vendorInfo: [
          {
            first: "",
            title: "",
            last: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        remitInfo: [
          {
            title: "",
            last: "",
            first: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        receiveInfo: [
          {
            title: "",
            first: "",
            last: "",
            vendno: "",
            vendor_id: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            email: "",
            fax: "",
          },
        ],
        auto_generate_order_number: false,
        order_number: "",
        order_status: "",
        order_type_id: "",
        order_type: "",
        bu_id: "",
        rma: "",
        vendor_so: "",
        ordered_date: "",
        delivery_date: "",
        pay_term_id: 14,
        fob: "",
        vendor_order_number: "",
        step_number: "",
        saturday_delivery: false,
        signature_required: false,
        outbound_carrier: "",
        inbound_carrier: "",
        order_notes: "",
        flex_field: [],
        order_line: [],
      },
      // Rules for validation
      required: [(v) => !!v || "Field is required"],
      rmaValidataion: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?:[ A-Za-z0-9\s\S]){3,25}$/.test(v) ||
          "Please enter at least one text or number, RMA should be greater than 3 characters and less than 25 characters",
      ],
      priceValid: [
        (v) => parseInt(v) >= 0 || "Field is required",
        (v) => /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/.test(v) || "Upto 2 decimal places are allowed",
      ],
      max20RuleReq: [(v) => !!v || "Field is required", (v) => (v || "").length <= 20 || "Max Length must be 20 characters or less"],
      vendorRule: [(v) => /^([a-zA-Z0-9]+)$/.test(v) || "Only alphanumeric  characters allowed"],
      max30RuleReq: [
        (v) => !!v || "Field is required",
        (v) => (v || "").length <= 30 || "Max Length must be 30 characters or less",
        (v) => (v || "").length >= 5 || "Min Length must be 5 characters or More",
      ],
      line_required: [(v) => parseInt(v) >= 0 || "Field is required"],
      qtyRule: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
      //Table Headers
      flexHeader: [
        {
          text: "Flex Attribute",
          align: "start",
          value: "flex_code",
          class: "primary customwhite--text",
        },
        {
          text: "Flex Value",
          value: "flex_data",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      lineItemHeader: [
        {
          text: "Line#",
          align: "start",
          value: "line_number",
          class: "primary customwhite--text",
        },
        {
          text: "Line Type",
          value: "order_line_text",
          class: "primary customwhite--text",
        },
        {
          text: "Ship Part Number",
          value: "sh_part_number",
          class: "primary customwhite--text",
        },
        {
          text: " Receive Part Number",
          value: "receive_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Outbound Warehouse",
          value: "sh_from_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Warehouse",
          value: "receive_in_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Shipment Order Qty",
          value: "sh_quantity",
          class: "primary customwhite--text",
        },
        {
          text: "Receive Order Qty",
          value: "receive_quantity",
          class: "primary customwhite--text",
        },
        {
          text: "Receive Price ($)",
          value: "receive_unit_cost",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      headersWarranty: [
        {
          text: "Serial No",
          align: "start",
          value: "serial_number",
          class: "primary customwhite--text",
        },
        {
          text: "Problem Description",
          value: "problem_description",
          class: "primary customwhite--text",
        },
        {
          text: "Tracking",
          value: "return_tracking_number",
          class: "primary customwhite--text",
        },
        {
          text: "Warranty",
          value: "warranty_status",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      expected_serial_number_obj: {
        serial_number: "",
        warranty_status: "N",
        asset_tag: "",
        return_tracking_number: "",
        problem_description: "",
      },
      temp_order_line: {
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_ship_by_date: "",
        sh_grade: "",
        receive_part_id: "",
        receive_part_number: "",
        receive_part_description: "",
        receive_in_warehouse_id: "",
        receive_in_warehouse: "",
        receive_in_inventory_bucket: "",
        receive_quantity: "",
        receive_unit_cost: "0.00",
        receive_rma: "",
        receive_grade: "",
        receive_delivery_date: "",
        receive_ext_cost: "",
        expected_serial_number: [],
        line_comment: "",
        vendor_part_number: "",
        po_line_ref: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: false,
        showPartShip: false,
        showBuild: "",
        showSerial: "",
      },
      flex_field_Att: "",
      flex_field_value: "",
      sequence_number: 0,
      temp_po_id: 0,
      updatedSerialObj: {
        serial_number: "",
        warranty_status: "N",
        asset_tag: "",
        return_tracking_number: "",
        problem_description: "",
      },
    };
  },
  async created() {
    if (this.$route?.params?.purchaseOrderId) {
      this.route = this.$route.path.replace("/", "").split("/")[0];
    } else {
      this.route = this.$route.path.replace("/", "");
    }
    let projectData = await commonApiService.getProject("get", this.userId);
    if (projectData?.ProjectInfo) this.projectList = projectData.ProjectInfo;
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    let warrantyData = await commonPoService.getWarrentyData("get", this.userId);
    if (warrantyData?.WarrantyData) {
      this.warrantyList = warrantyData.WarrantyData.map((x) => ({
        code: x.code,
        description: x.description,
      }));
    }
  },
  //Refresh  Warning
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  //Refresh to check if Router is changes
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (window.confirm("Leave without saving?")) {
        next();
      } else {
        return false;
      }
    }
    next();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.purchaseOrderJson.ordered_date
        ? moment(String(this.purchaseOrderJson.ordered_date)).format("MM/DD/YYYY")
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    shipcomputeDateFormattedMomentjs() {
      return this.purchaseOrderJson.delivery_date
        ? moment(String(this.purchaseOrderJson.delivery_date)).format("MM/DD/YYYY")
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    tempDelDatecomputeDateFormattedMomentjs() {
      return this.temp_order_line.receive_delivery_date
        ? moment(String(this.temp_order_line.receive_delivery_date)).format("MM/DD/YYYY")
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    shipByDateDatecomputeDateFormattedMomentjs() {
      return this.temp_order_line.sh_ship_by_date
        ? moment(String(this.temp_order_line.sh_ship_by_date)).format("MM/DD/YYYY")
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
  },
  async mounted() {
    //this is when we rediect from your Draft order to sales order Add Page
    if (this.$route?.params?.purchaseOrderId) {
      // To get the id frm router
      let po_id = atob(this.$route.params?.purchaseOrderId);
      this.temp_po_id = po_id;
      this.disableProject = true;
      this.disableBusinessDep = false;
      this.disable_Step6 = true;
      this.disableVendor = true;
      this.disableBusiness = true;
      this.disableOrderType = true;
      this.showVenAddress = true;
      this.disableEmitAdd = true;
      this.showRemitAddress = true;
      this.disableReceiveAdd = true;
      this.showReceiveAddress = true;
      //to get draft ofder JSON
      let orderDraftData = await commonPoService.getOrderDraftById("get", this.userId, po_id);
      this.draftData = orderDraftData[0]?.OJson;
      if (this.draftData) {
        this.purchaseOrderJson = JSON.parse(this.draftData);
        let projObj = this.projectList?.filter((x) => x.ProjKey == this.purchaseOrderJson.proj_id);
        this.projectName = projObj[0]?.Project;
        //mapping
        this.stepElement = this.purchaseOrderJson.step_number;
        this.venSearchKey = this.purchaseOrderJson?.vendorInfo[0]?.company;
        this.remitSearchKey = this.purchaseOrderJson?.remitInfo[0]?.company;
        this.receiveSearchKey = this.purchaseOrderJson?.receiveInfo[0]?.company;
        // TO get Business Data
        let businessObj = {
          UserId: this.userId,
          ProjKey: this.purchaseOrderJson.proj_id,
          BuKey: 0, // Do not Delete
          IncludeEmptyBU: 0, // Do not Delete
          WithinSameLocation: 1, // Do not Delete
          loca_key: this.purchaseOrderJson?.receiveInfo[0]?.vendor_id ? 0 : this.purchaseOrderJson.receiveInfo[0].vendor_id,
        };
        this.getBussinessData(businessObj);
        let orderObj = {
          UserId: this.userId,
          bu_key: this.purchaseOrderJson.bu_id,
          pass: 1, // Do not Delete
        };
        this.getOrderTypeData(orderObj);
        // To get LIne type DDL
        if (this.purchaseOrderJson.step_number > 4) {
          this.getLineTypeData();
        }
        if (this.purchaseOrderJson.step_number > 5) {
          let carrierObj = {
            UserId: this.userId,
            bu_key: this.purchaseOrderJson.bu_id,
            vendso: this.purchaseOrderJson.vendno,
            order_type: this.purchaseOrderJson.order_type_id,
            sbu_key: 0,
          };
          this.getCarrierData(carrierObj);
        }
      }
    }
    //
  },
  methods: {
    // To open Dialog box of resptective address
    addressDialog(item) {
      (this.showAddressDialog = true), (this.addressDialogTitle = item);
    },
    // Scroll Check method
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    // To go to the top
    toTop() {
      this.$vuetify.goTo(0);
    },
    // Continue click of step 2
    async step2Clicked() {
      this.stepElement = 2; //Do Not Remove
      this.isEditing = true;
      this.disableProject = true;
      this.projectddl = true;
      let projObj = this.projectList.filter((x) => x.ProjKey == this.purchaseOrderJson.proj_id);
      this.projectName = projObj[0]?.Project;
      this.disableVen = false;
    },
    // To get Vendor Data
    async getVenData() {
      if (this.venSearchKey) {
        this.venSearchKey = this.venSearchKey?.toUpperCase();
        if (this.venSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.purchaseOrderJson.proj_id,
            SearchStr: this.venSearchKey,
            pass: 1, // DO not Delte
            strActive: 1,
          };
          let resp = await commonPoService.getVendorData("post", obj, false, true);
          if (resp?.vendorInfo) {
            this.vendorList = resp.vendorInfo.map((x) => ({
              title: x.title,
              last: x.first + " " + x.last,
              vendor_id: x.vendor_id,
              vendno: x.vendno,
              company: x.company,
              address1: x.address1,
              address2: x.address2,
              city: x.city,
              state: x.state,
              zip: x.zip,
              country: x.country,
              phone1: x.phone1,
              phone2: x.phone2,
              cell: x.cell,
              email: x.email,
              fax: x.fax,
            }));
          }
        } else {
          this.clearVenAddressData();
        }
      }
    },
    // Clear Vendor Data
    clearVenAddressData() {
      this.vendorList = [];
      this.showVenAddress = false;
      this.purchaseOrderJson.vendorInfo = [
        {
          title: "",
          last: "",
          vendor: "",
          vendor_id: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          phone1: "",
          phone2: "",
          cell: "",
          email: "",
          fax: "",
        },
      ];
    },
    // SHow Vendor
    showVendor(item) {
      Object.assign(this.purchaseOrderJson.vendorInfo[0], item);
      this.venSearchKey = item.company;
      this.vendorList = [];
      this.showVenAddress = true;
    },
    // on thrid step
    async step3Clicked() {
      this.stepElement = 3; // Do not Delele
      this.disableProject = true;
      this.disableVendor = true;
      this.sameAsVendorCheck = true;
      this.sameAsVendor();
    },
    // To get the remit
    async getRrmitData() {
      if (this.remitSearchKey) {
        this.remitSearchKey = this.remitSearchKey?.toUpperCase();
        if (this.remitSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.purchaseOrderJson.proj_id,
            SearchStr: this.remitSearchKey,
            pass: 2, // Do not Delete
            strActive: 1,
          };
          this.showRemitDDL = true;
          let resp = await commonPoService.getVendorData("post", obj, false, true);
          if (resp?.vendorInfo) {
            this.remitList = resp.vendorInfo.map((x) => ({
              title: x.title,
              last: x.first + " " + x.last,
              vendor_id: x.vendor_id,
              vendno: x.vendno,
              company: x.company,
              address1: x.address1,
              address2: x.address2,
              city: x.city,
              state: x.state,
              zip: x.zip,
              country: x.country,
              phone1: x.phone1,
              phone2: x.phone2,
              cell: x.cell,
              email: x.email,
              fax: x.fax,
            }));
          }
        } else {
          this.clearRemitData();
        }
      }
    },
    //clear remit address
    clearRemitData() {
      this.remitList = [];
      this.sameAsVendorCheck = false;
      this.showRemitAddress = false;
      this.purchaseOrderJson.remitInfo = [
        {
          title: "",
          last: "",
          vendor: "",
          vendor_id: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          phone1: "",
          phone2: "",
          cell: "",
          email: "",
          fax: "",
        },
      ];
    },
    // to show Address
    showRemit(item) {
      if (item) {
        this.showRemitDDL = false;
        this.showRemitAddress = true;
      }
      Object.assign(this.purchaseOrderJson.remitInfo[0], item);
      this.remitSearchKey = item.company;
      this.remitList = [];
    },
    // Same as customer Checkbox
    sameAsVendor() {
      if (this.sameAsVendorCheck) {
        Object.assign(this.purchaseOrderJson.remitInfo[0], this.purchaseOrderJson.vendorInfo[0]);
        this.showRemitAddress = true;
        this.remitSearchKey = this.venSearchKey;
      } else {
        this.purchaseOrderJson.remitInfo[0] = [];
        this.showRemitAddress = false;
        this.remitSearchKey = "";
      }
    },
    // To get the remit
    async getReceiveData() {
      if (this.receiveSearchKey) {
        this.receiveSearchKey = this.receiveSearchKey?.toUpperCase();
        if (this.receiveSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.purchaseOrderJson.proj_id,
            SearchStr: this.receiveSearchKey,
          };
          this.showReceivedDDL = true;
          let resp = await commonPoService.getReceiveData("post", obj, false, true);
          if (resp?.ReceiveAddress) {
            this.receiveList = resp.ReceiveAddress.map((x) => ({
              title: x.rt_title,
              last: x.rt_first + " " + x.rt_last,
              vendor_id: x.rt_comp_id,
              vendno: x.rt_custno,
              company: x.rt_company,
              address1: x.rt_address1,
              address2: x.rt_address2,
              city: x.rt_city,
              state: x.rt_state,
              zip: x.rt_zip,
              country: x.rt_country,
              phone1: x.rt_phone1,
              phone2: x.rt_phone2,
              cell: x.rt_cell,
              email: x.rt_email,
              fax: x.rt_fax,
            }));
          }
        } else {
          this.clearReceiveData();
        }
      }
    },
    //clear Receive address
    clearReceiveData() {
      this.showReceiveAddress = false;
      this.receiveList = [];
      this.showReceivedDDL = false;
      this.purchaseOrderJson.receiveInfo = [
        {
          title: "",
          last: "",
          vendor: "",
          vendor_id: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          phone1: "",
          phone2: "",
          cell: "",
          email: "",
          fax: "",
        },
      ];
    },
    // to show  receive Address
    showReceive(item) {
      if (item) {
        this.showReceivedDDL = false;
        this.showReceiveAddress = true;
      }
      Object.assign(this.purchaseOrderJson.receiveInfo[0], item);

      this.receiveSearchKey = item.company;
      this.receiveList = [];
    },
    // STep 4 on Click
    async step4Clicked() {
      this.showReceivedDDL = false;
      this.showRemitDDL = false;
      if (this.showReceivedDDL) {
        this.receiveSearchKey = "";
      }
      if (this.showRemitDDL) {
        this.remitSearchKey = "";
      }
      this.stepElement = 4; // Do not Delete
      this.disableProject = true;
      this.disableVendor = true;
      this.disableEmitAdd = true;
      this.disableReceiveAdd = true;
      let businessObj = {
        UserId: this.userId,
        ProjKey: this.purchaseOrderJson.proj_id,
        BuKey: 0, // Do not Delete
        IncludeEmptyBU: 0, // Do not Delete
        WithinSameLocation: 1, // Do not Delete
        loca_key: this.purchaseOrderJson.receiveInfo[0].vendor_id,
      };
      this.getBussinessData(businessObj);
    },
    // on Change Business Function
    async onChangeBusiness(business) {
      let busObj = this.businessList.filter((x) => x.bu_id == business);
      this.businessName = busObj[0]?.business;
      this.purchaseOrderJson.pay_term_id = busObj[0]?.bu_payterm;
      this.disableOrderType = false;
      let businessObj = {
        UserId: this.userId,
        bu_key: business,
        loca_key: this.purchaseOrderJson.receiveInfo[0].vendor_id,
        pass: 1, // Do not Delete
      };
      this.getOrderTypeData(businessObj);
    },
    // On Step 5
    async step5Clicked() {
      if (this.$refs.purchaseOrderForm.validate()) {
        this.stepElement = 5; // This is Hard Coded This is Step Key
        this.disableProject = true;
        this.disableVendor = true;
        this.disableRemit = true;
        this.disableReceiveAdd = true;
        this.disablePurchaseForm = true;
        this.disableBusiness = true;
        this.disableOrderType = true;
        this.purchaseOrderJson.step_number = 5;
        let orderObj = this.orderTypeList.filter((x) => x.ord_code == this.purchaseOrderJson.order_type_id);
        this.purchaseOrderJson.order_type_id = orderObj[0]?.ord_code;
        this.purchaseOrderJson.order_type = orderObj[0]?.ordCodedesc;
        this.getLineTypeData();
        this.postTempPO(this.purchaseOrderJson);
      }
    },
    async getLineTypeData() {
      let obj = {
        ProjKey: this.purchaseOrderJson.proj_id,
        UserId: this.userId,
        POId: 0,
        line_id: 0,
      };
      let LineTypeData = await commonPoService.postOrderTypeData("post", obj);
      if (LineTypeData?.LineTypeInfo) {
        this.LineTypeList = LineTypeData.LineTypeInfo.map((x) => ({
          so_type_id: x.sotype_id,
          so_desc: x.so_desc,
          so_type: x.so_type,
          to_ship: x.to_ship,
          to_return: x.to_return,
          to_disable_quantity: x.to_disable_quantity,
          to_disable_ReturnSel: x.to_disable_ReturnSel,
        }));
        if (this.purchaseOrderJson.step_number > 5) this.validateLineType();
      }
    },
    async receivePartNumber() {
      if (this.partReturnSearchKey) {
        this.clearPartReceive();
        this.partReturnSearchKey = this.partReturnSearchKey?.toUpperCase();
        if (this.partReturnSearchKey.length > 2) {
          this.showPartRetDetails = true;
          this.showPartRetDetails = true;
          let returnSerachObj = {
            UserId: this.userId,
            SearchStr: this.partReturnSearchKey,
            order_bu_key: this.purchaseOrderJson.bu_id,
          };
          let returnSearchData = await commonPoService.postReturnSearch("post", returnSerachObj, false, true);
          if (returnSearchData != "") this.returnResultList = returnSearchData?.Resultset;
        }
      }
    },
    // Return Part Desc
    async showReturnPartDesc(item) {
      this.showPartData = true;
      this.returnResultList = [];
      this.vendorDisable = false;
      this.partReturnSearchKey = item.part_num;
      this.temp_order_line.receive_part_id = item.part_id;
      this.temp_order_line.receive_part_number = item.part_num;
      this.temp_order_line.receive_part_description = item.part_desc;
      let warehouseObj = {
        part_num: item.part_num,
        potype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.purchaseOrderJson.bu_id,
        order_type: this.purchaseOrderJson.order_type_id,
        po_key: 0,
        UserId: this.userId,
        loca_key: this.purchaseOrderJson.receiveInfo[0].vendor_id,
        line_id: 0,
      };
      if (this.showPartShip) {
        this.temp_order_line.sh_part_id = this.temp_order_line.receive_part_id;
        this.temp_order_line.sh_part_number = this.temp_order_line.receive_part_number;
        this.temp_order_line.sh_part_description = this.temp_order_line.receive_part_description;
        this.partShipSearchKey = this.partReturnSearchKey;
        this.showPartDetails = true;
      }
      this.getWarehouseData(warehouseObj, "receive");
    },
    OnChangeLineType(value) {
      if (value.order_line_type == 1) {
        this.panel = [0];
      } else if (value.order_line_type == 8) {
        this.panel = [1];
      } else {
        this.panel = [0, 1];
      }
      this.disableLine = true;
      let obj = this.LineTypeList.filter((x) => x.so_type_id == value);
      this.showPartShip = obj[0].to_ship == 1 ? true : false;
      this.showPartRet = obj[0].to_return == 1 ? true : false;
      this.showFlex = true;
      this.temp_order_line.order_line_text = obj[0].so_desc;
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      this.diableShipQty = obj[0].to_disable_quantity == 1 ? true : false;
      this.disablePartShip = obj[0].to_disable_ReturnSel == 1 ? true : false;
      // Default Value 1 for CR and EX Line Types
      if (value == 2 || value == 6) {
        this.temp_order_line.sh_quantity = 1;
      }
      this.temp_order_line.receive_rma = this.purchaseOrderJson.rma;
    },
    onChangeSameAsShip() {
      if (this.sameAsShip) {
        this.temp_order_line.sh_part_id = this.temp_order_line.receive_part_id;
        this.temp_order_line.sh_part_number = this.temp_order_line.receive_part_number;
        this.temp_order_line.sh_part_description = this.temp_order_line.receive_part_description;

        this.temp_order_line.sh_ship_by_date = this.temp_order_line.receive_delivery_date;
        this.temp_order_line.sh_quantity = this.temp_order_line.receive_quantity;
        this.partShipSearchKey = this.partReturnSearchKey;
        this.showPartDetails = true;
        let returnwareId = this.shipWareList.filter((x) => x.ware_id == this.temp_order_line.receive_in_warehouse_id);
        if (returnwareId?.ware_id) this.temp_order_line.sh_from_warehouse_id = this.temp_order_line.receive_in_warehouse_id;
      }
    },
    clearLineItem() {
      this.vendorDisable = true;
      this.disableBuildSave = true;
      this.showPartShipDetails = false;
      this.showPartData = false;
      this.buildPartNumList = false;
      this.showPartRetDetails = false;
      this.showPartDetails = false;
      this.disableQty = false;
      this.showBuildExpand = false;
      this.showStockStatus = false;
      this.disablePartRecieve = false;
      this.showLineUpdate = false;
      this.temp_order_line = {
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_ship_by_date: "",
        sh_grade: "",
        receive_part_id: "",
        receive_part_number: "",
        receive_part_description: "",
        receive_in_warehouse_id: "",
        receive_in_warehouse: "",
        receive_in_inventory_bucket: "",
        receive_quantity: "",
        receive_unit_cost: "0.00",
        receive_rma: "",
        receive_grade: "",
        receive_delivery_date: "",
        receive_ext_cost: "",
        expected_serial_number: [],
        line_comment: "",
        vendor_part_number: "",
        po_line_ref: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: false,
        showPartShip: false,
        showBuild: "",
        showSerial: "",
      };
      this.showLineDelete = false;
      this.showExpectedSr = false;
      this.sameAsShip = false;
      (this.shipPartName = ""), (this.partBuildPartNum = "");
      this.buildToQty = "";
      this.shipPartDesc = "";
      this.partShipSearchKey = "";
      this.partReturnSearchKey = "";
      this.expected_serial_number_obj = {
        serial_number: "",
        warranty_status: "N",
        asset_tag: "",
        return_tracking_number: "",
        problem_description: "",
      };
      this.disableLine = false;
      this.showPartShip = false;
      this.showFlex = false;
      this.showPartRet = false;
      this.showexpIcon = false;
      this.showBuildIcon = false;
      this.showBuildExpand = false;
      this.$refs?.PartRecieveForm?.reset();
      this.$refs?.PartRecieveForm?.resetValidation();
      this.$refs?.PartShipForm?.reset();
      this.$refs?.PartShipForm?.resetValidation();
      this.$refs?.vendorPartForm?.reset();
      this.$refs?.vendorPartForm?.resetValidation();
    },
    // Cancel Line Item
    cancelLineItems() {
      this.clearLineItem();
      this.showLineItem = false;
      this.editLinePart = true;
      this.showLineUpdate = false;
    },
    editSerialNum(item) {
      this.updatedSerialObj = JSON.parse(JSON.stringify(item));
      this.showSerialNum = true;
      this.temp_waybill_id = item.temp_waybill_id;
    },
    onClickDeleteSerial() {
      this.showSerialNum = false;
      this.temp_order_line.expected_serial_number = this.temp_order_line.expected_serial_number.filter(
        (x) => x.temp_waybill_id !== this.temp_waybill_id
      );
    },
    onClickUpdateWaybill(updatedSerialObj) {
      this.temp_order_line.expected_serial_number = this.temp_order_line.expected_serial_number.map((p) =>
        p.temp_waybill_id == this.temp_waybill_id
          ? {
              ...p,
              serial_number: updatedSerialObj.serial_number,
              asset_tag: updatedSerialObj.asset_tag,
              problem_description: updatedSerialObj.problem_description,
              warranty_status: updatedSerialObj.warranty_status,
              return_tracking_number: updatedSerialObj.return_tracking_number,
            }
          : p
      );
      this.showSerialNum = false;
    },
    //Get Ship  Data
    async partShipData() {
      this.clearPartShip();
      if (this.partShipSearchKey) {
        this.partShipSearchKey = this.partShipSearchKey?.toUpperCase();
        if (this.partShipSearchKey.length > 2) {
          this.showPartShipDetails = true;
          let shipDataObj = {
            UserId: this.userId,
            SearchStr: this.partShipSearchKey,
            order_bu_key: this.purchaseOrderJson.bu_id,
            potype_key: this.temp_order_line.order_line_type,
            line_key: 0,
            left_part_key: this.temp_order_line.receive_part_id ? this.temp_order_line.receive_part_id : 0,
          };
          this.shipWareList = [];
          this.returnWareList = [];
          let shipDataSearch = await commonPoService.postShipSearch("post", shipDataObj, false, true);
          if (shipDataSearch?.Resultset) this.partResultList = shipDataSearch.Resultset;
        }
      }
    },
    // PART DESC
    async showPartDesc(item) {
      this.showPartDetails = true;
      this.partResultList = [];
      this.temp_order_line.sh_part_id = item.part_id;
      this.temp_order_line.sh_part_number = item.part_num;
      this.temp_order_line.sh_part_description = item.part_desc;
      this.shipPartName = item.part_num;
      this.partShipSearchKey = item.part_num;
      this.shipPartDesc = item.part_desc;
      this.shipPartKey = item.part_id;
      let shipWareObj = {
        part_num: item.part_num,
        order_type: this.purchaseOrderJson.order_type,
        potype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.purchaseOrderJson.bu_id,
        po_key: 0,
        UserId: this.userId,
        loca_key: this.purchaseOrderJson.receiveInfo[0].vendor_id,
        line_id: 0,
      };
      this.vendorDisable = false;
      this.getWarehouseData(shipWareObj, "ship");
    },
    onClickSaveWaybill(obj) {
      if (this.$refs.ExpectedSrForm.validate()) {
        if (this.temp_order_line.expected_serial_number.length <= 0) {
          let expected_ord_seq =
            this.temp_order_line.expected_serial_number.length == undefined || this.temp_order_line.expected_serial_number.length == null
              ? 0
              : this.temp_order_line.expected_serial_number.length;
          this.temp_waybill_id = this.temp_waybill_id + 1;
          obj["temp_waybill_id"] = this.temp_waybill_id;
          obj["sequence_number"] = expected_ord_seq + 1;
          this.temp_order_line.expected_serial_number.push(obj);
          this.expected_serial_number_obj = {
            serial_number: "",
            warranty_status: "N",
            asset_tag: "",
            return_tracking_number: "",
            problem_description: "",
          };
          this.$refs.ExpectedSrForm.resetValidation();
        } else {
          let validSerial = {};
          if (!obj.serialNum) {
            validSerial = this.temp_order_line.expected_serial_number.find((x) => x.return_tracking_number == obj.trackNum);
          } else {
            validSerial = this.temp_order_line.expected_serial_number.find((x) => x.serial_number == obj.serialNum);
          }
          if (!obj.trackNum && !obj.validSerial)
            validSerial = this.temp_order_line.expected_serial_number.find((x) => x.serial_number == obj.serialNum);
          else {
            validSerial = this.temp_order_line.expected_serial_number.find(
              (x) => x.return_tracking_number == obj.trackNum || x.serial_number == obj.serialNum
            );
          }
          if (!validSerial) {
            let expected_ord_seq =
              this.temp_order_line.expected_serial_number.length == undefined || this.temp_order_line.expected_serial_number.length == null
                ? 0
                : this.temp_order_line.expected_serial_number.length;
            this.temp_waybill_id = this.temp_waybill_id + 1;
            (obj["temp_waybill_id"] = this.temp_waybill_id), (obj["sequence_number"] = expected_ord_seq + 1);
            this.temp_order_line.expected_serial_number.push(obj);
            this.expected_serial_number_obj = {
              serial_number: "",
              warranty_status: "N",
              asset_tag: "",
              return_tracking_number: "",
              problem_description: "",
            };
            this.$refs.ExpectedSrForm.resetValidation();
          } else {
            if (validSerial?.serial_number) {
              let Alert = {
                type: "error",
                isShow: true,
                message: "The Serial number " + this.expected_serial_number_obj.serial_number + " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              validSerial = "";
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "The Tracking number " + this.expected_serial_number_obj.trackNum + " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              validSerial = "";
            }
          }
        }
      }
    },
    // On Click Add Part List Item
    submitLineItems() {
      this.temp_order_line.receive_delivery_date = Utility.convertLocalToUTC(this.temp_order_line?.receive_delivery_date);
      this.temp_order_line.sh_ship_by_date = Utility.convertLocalToUTC(this.temp_order_line?.sh_ship_by_date);
      this.temp_order_line.showSerial = this.temp_order_line?.expected_serial_number.length > 0;
      let order_line_num =
        this.purchaseOrderJson.order_line.length == null || this.purchaseOrderJson.order_line.length == undefined
          ? 0
          : this.purchaseOrderJson.order_line.length;
      if (this.showPartShip && this.showPartRet) {
        if (this.$refs.PartShipForm.validate() && this.$refs.PartRecieveForm.validate()) {
          this.temp_order_line.receive_in_warehouse_id = this.temp_order_line?.receive_in_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.purchaseOrderJson.order_line.push(this.temp_order_line);
          this.showLineItem = false;
          this.clearLineItem();
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm.validate()) {
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.temp_order_line.receive_quantity = "";
          this.purchaseOrderJson.order_line.push(this.temp_order_line);
          this.showLineItem = false;
          this.clearLineItem();
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm.validate()) {
          this.temp_order_line.receive_in_warehouse_id = this.temp_order_line?.receive_in_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sh_quantity = 0;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.purchaseOrderJson.order_line.push(this.temp_order_line);
          this.showLineItem = false;
          this.clearLineItem();
        }
      }
      this.totalRecords = this.purchaseOrderJson.order_line.length;
    },
    //Edit LIne Item
    editLineItem() {
      if (!this.showPartRet) this.temp_order_line.receive_quantity = "";
      this.temp_order_line.receive_in_warehouse_id = this.temp_order_line?.receive_in_warehouse_id.split("_")[0];
      this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
      let tempObj = this.purchaseOrderJson.order_line.map((p) =>
        p.line_number == this.temp_order_line.line_number ? (this.purchaseOrderJson.order_line = this.temp_order_line) : p
      );
      if (this.showPartShip && this.showPartRet) {
        if (this.$refs.PartShipForm.validate() && this.$refs.PartRecieveForm.validate()) {
          this.purchaseOrderJson.order_line = JSON.parse(JSON.stringify(tempObj));
          this.showLineItem = false;
          this.showPartData = true;
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm.validate()) {
          this.purchaseOrderJson.order_line = JSON.parse(JSON.stringify(tempObj));
          this.showLineItem = false;
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm.validate()) {
          this.purchaseOrderJson.order_line = JSON.parse(JSON.stringify(tempObj));
          this.showLineItem = false;
        }
      }
    },
    onClickEditList(item) {
      this.panel = [0, 1];
      this.showPartDetails = true;
      this.showPartData = true;
      this.shipPartName = item.sh_part_number;
      this.shipPartDesc = item.sh_part_description;
      this.showLineItem = true;
      this.showLineUpdate = true;
      this.temp_order_line = JSON.parse(JSON.stringify(item));
      if (!item?.sh_from_warehouse_id?.includes("_") || !item?.receive_in_warehouse_id?.includes("_")) {
        this.temp_order_line.sh_from_warehouse_id = item?.sh_from_warehouse_id?.concat("_", item?.sh_from_inventory_bucket);
        this.temp_order_line.receive_in_warehouse_id = item?.receive_in_warehouse_id?.concat("_", item?.receive_in_inventory_bucket);
      }
      this.showPartsItem = true;
      this.disableLine = true;
      this.showPartShip = item.showPartShip;
      this.showPartRet = item.showPartRet;
      this.showexpIcon = item.showSerial;
      this.showBuildIcon = item.showBuild;
      this.showFlex = true;
      this.edit_line_num = item.line_number;
      this.partShipSearchKey = item.sh_part_number;
      this.partReturnSearchKey = item.receive_part_number;
      let partnumber_temp = "";
      if (item.showPartShip == false) {
        partnumber_temp = item.receive_part_number;
      } else {
        partnumber_temp = item.sh_part_number;
      }
      let shipWareObj = {
        part_num: partnumber_temp,
        order_type: this.purchaseOrderJson.order_type,
        potype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.purchaseOrderJson.bu_id,
        po_key: 0,
        UserId: this.userId,
        loca_key: this.purchaseOrderJson.receiveInfo[0].vendor_id,
        line_id: 0,
      };
      this.getWarehouseData(shipWareObj, "viewLine");
    },
    // Step 6
    async step6Clicked() {
      this.stepElement = 6; // This is hard COde
      this.disableProject = true;
      this.disableVendor = true;
      this.disableRemit = true;
      this.disableReceiveAdd = true;
      this.disablePurchaseForm = true;
      let carrierObj = {
        UserId: this.userId,
        bu_key: this.purchaseOrderJson.bu_id,
        vendso: this.purchaseOrderJson.vendno,
        order_type: this.purchaseOrderJson.order_type_id,
        sbu_key: 0,
      };
      this.getCarrierData(carrierObj);
      this.purchaseOrderJson.step_number = 6;
      this.postTempPO(this.purchaseOrderJson);
      this.validateLineType();
    },
    // Step 7
    async step7Clicked() {
      if (this.$refs.CarrierLevlInfoForm.validate()) {
        this.stepElement = 7; // this is hard coded
        this.disableProject = true;
        this.disableVendor = true;
        this.disableRemit = true;
        this.disableReceiveAdd = true;
        this.disablePurchaseForm = true;
        this.disableShip = true;
        this.disableSubmit = false;
        this.disableShippingSection = true;
        this.purchaseOrderJson.step_number = 7;
      }
    },
    // Step 8
    async step8Clicked() {
      this.stepElement = 8; // this is hard coded
      this.disableProject = true;
      this.disableVendor = true;
      this.disableRemit = true;
      this.disableReceiveAdd = true;
      this.disablePurchaseForm = true;
      this.disableShip = true;
      this.disableSubmit = false;
      this.disableShippingSection = true;
      this.disableNotes = true;
      this.purchaseOrderJson.step_number = 8;
      this.postTempPO(this.purchaseOrderJson);
    },
    // common function for bussiness API because it will be called multiple time
    async getBussinessData(businessObject) {
      let businessData = await commonApiService.postBusinessData("post", businessObject);
      this.businessList = businessData.map((x) => ({
        bu_id: x.Bu_id,
        business: x.Business,
        bu_payterm: x.pterms,
      }));
      let busObj = this.businessList.filter((x) => x.bu_id == this.purchaseOrderJson.bu_id);
      this.businessName = busObj[0]?.business;
    },
    //Clear Flex
    clearFlexItem() {
      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_field_value = "";
      this.$refs.flexForm.resetValidation();
    },
    onClickFlexAdd(flex_field_Att, flex_value) {
      if (this.showLineItem) {
        let flexCount =
          this.temp_order_line.flex_field.length == undefined || this.temp_order_line.flex_field.length == null
            ? 0
            : this.temp_order_line.flex_field.length;
        let obj = {
          sequence_number: flexCount + 1,
          flex_code: flex_field_Att,
          flex_data: flex_value,
        };
        this.temp_order_line.flex_field.push(obj);
      } else {
        let flexCount =
          this.purchaseOrderJson.flex_field.length == undefined || this.purchaseOrderJson.flex_field.length == null
            ? 0
            : this.purchaseOrderJson.flex_field.length;
        let obj = {
          sequence_number: flexCount + 1,
          flex_code: flex_field_Att,
          flex_data: flex_value,
        };
        this.purchaseOrderJson.flex_field.push(obj);
      }
      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_field_value = "";
      this.$refs.flexForm.resetValidation();
    },
    //onClick Delete
    onClickLineDelete(item) {
      this.deleteDialog = true;
      if (item == "Flex") this.deleteFlexItem = true;
      else if (item == "Line") {
        this.deleteLine = true;
      }
    },
    flexDetails(item) {
      this.showFlexDialog = true;
      this.sequence_number = item.sequence_number;
      this.flex_field_Att = item.flex_code;
      this.flex_field_value = item.flex_data;
      this.showLineDelete = true;
      this.showUpdateLine = true;
    },
    // Update Flex
    updateFlex(flex_field_Att, flex_field_value) {
      if (this.showLineItem) {
        this.temp_order_line.flex_field = this.temp_order_line.flex_field.map((p) =>
          p.sequence_number == this.sequence_number ? { ...p, flex_code: flex_field_Att, flex_data: flex_field_value } : p
        );
      } else {
        this.purchaseOrderJson.flex_field = this.purchaseOrderJson.flex_field.map((p) =>
          p.sequence_number == this.sequence_number ? { ...p, flex_code: flex_field_Att, flex_data: flex_field_value } : p
        );
      }
      this.showFlexDialog = false;
      this.showUpdateLine = false;
      this.flex_field_Att = "";
      this.flex_field_value = "";
      this.$refs.flexForm.resetValidation();
    },
    // Clear Part Return Items
    clearPartReceive() {
      this.partShipSearchKey = "";
      this.$refs.PartRecieveForm.resetValidation();
      this.temp_order_line.receive_part_number = "";
      this.temp_order_line.receive_unit_cost = "0.00";
      this.temp_order_line.receive_part_description = "";
      this.temp_order_line.receive_part_number = "";
      this.temp_order_line.receive_grade = "";
      this.temp_order_line.receive_quantity = "";
      this.temp_order_line.receive_ext_cost = "0.00";
      this.temp_order_line.receive_part_description = "";
      this.temp_order_line.receive_in_inventory_bucket = "";
      this.temp_order_line.receive_in_warehouse = "";
      this.temp_order_line.receive_in_warehouse_id = "";
      this.returnWareList = [];
      this.expected_serial_number = [];
      this.showPartData = false;
      this.clearPartShip();
    },
    clearPartShip() {
      this.$refs?.PartShipForm?.resetValidation();
      this.shipPartName = "";
      this.temp_order_line.sh_ship_by_date = "";
      this.shipPartDesc = "";
      this.temp_order_line.sh_price = "";
      this.temp_order_line.sh_quantity = "";
      this.temp_order_line.sh_from_warehouse_id = "";
      this.temp_order_line.sh_part_number = "";
      this.temp_order_line.sh_part_description = "";
      this.temp_order_line.sh_grade = "";
      this.disableQty = false;
      this.showPartDetails = false;
      this.shipWareList = [];
      this.temp_order_line.sh_from_warehouse = "";
      this.temp_order_line.sh_from_inventory_bucket = "";
      this.temp_order_line.sh_part_id = "";
    },
    // Part Number Change
    onPartReturnChange(warehouse_id) {
      let returnShipObj = this.returnWareList.filter((x) => x.ware_id == warehouse_id);
      this.temp_order_line.receive_in_inventory_bucket = returnShipObj[0].bucket;
      this.temp_order_line.receive_in_warehouse = returnShipObj[0].ware;
      this.temp_order_line.receive_in_warehouse_id = returnShipObj[0].ware_id;
      let cosmeticData = this.returnWareList.filter((x) => x.ware_id == warehouse_id);
      this.receiveCosmeticList = cosmeticData.map((x) => ({
        cg_desc: x.cg_desc,
        rm_id: x.rm_id,
      }));
    },
    // common function to get order type Data
    async getOrderTypeData(object) {
      let orderTypeData = await commonPoService.getOrderTypeList("post", object);
      if (orderTypeData?.OrderTypes) {
        this.notRequired = true;
        this.orderTypeList = orderTypeData.OrderTypes.map((x) => ({
          ord_code: x.ord_code,
          ordCodedesc: x.ordCodedesc,
        }));
      } else {
        this.notRequired = false;
        this.disableAuto = false;
      }
      if (orderTypeData?.PaymentTerms) {
        this.paymentList = orderTypeData.PaymentTerms.map((x) => ({
          payterm_id: x.list_id,
          payterm_desc: x.displaystring,
        }));
      }
    },
    // Common Function for Carrier Data
    async getCarrierData(carrierObj) {
      let carrierData = await commonPoService.postCarrierData("post", carrierObj);
      this.returnCarrierList = carrierData.InboundSLs;
      this.shipCarrierList = carrierData.OutboundSLs;
    },
    //common Function for Warehouse
    async getWarehouseData(shipObject, text) {
      let shipWarehouseList = await commonPoService.postWarehouseData("post", shipObject);
      if (shipWarehouseList == 400) {
        this.showexpIcon = false;
      } else {
        this.showexpIcon = shipWarehouseList?.SnInfo[0]?.enable_expected_sn;
        if (text != "viewLine") {
          this.temp_order_line.receive_unit_cost = shipWarehouseList?.CostInfo[0]?.defaultcost?.toFixed(2);
        }
        if (shipWarehouseList?.ShipWare) {
          this.shipWareList = shipWarehouseList?.ShipWare.map((x) => ({
            ware: x.ware,
            ware_id: x.ware_id + "_" + x.bucket,
            bucket: x.bucket,
            from_warebuck: x.from_warebuck,
            mess_bit: x.mess_bit,
            aqty: x.aqty,
            show_text: x.show_text,
            cg_desc: x.cg_desc,
            rm_id: x.rm_id,
          }));
        }
        if (shipWarehouseList?.ReceiveWare != "") {
          this.returnWareList = shipWarehouseList?.ReceiveWare?.map((x) => ({
            ware: x.ware,
            ware_id: x.ware_id + "_" + x.bucket,
            bucket: x.bucket,
            into_warebuck: x.into_warebuck,
            cg_desc: x.cg_desc,
            rm_id: x.rm_id,
          }));
        }
      }
    },
    onPartShipChange(warehouse_id) {
      let partShipObj = this.shipWareList.filter((x) => x.ware_id == warehouse_id);
      this.temp_order_line.sh_from_inventory_bucket = partShipObj[0].bucket;
      this.temp_order_line.sh_from_warehouse = partShipObj[0].ware;
      this.temp_order_line.sh_from_warehouse_id = partShipObj[0].ware_id;
      this.showStockStatus = partShipObj[0].mess_bit;
      this.stockMessage = partShipObj[0].show_text;
      let cosmeticData = this.shipWareList.filter((x) => x.ware_id == warehouse_id);
      this.shipCosmeticList = cosmeticData.map((x) => ({
        cg_desc: x.cg_desc,
        rm_id: x.rm_id,
      }));
    },
    onChangeShipping() {
      this.purchaseOrderJson.saturday_delivery=false;
      this.purchaseOrderJson.signature_required=false;
      let CarrierFlags = this.returnCarrierList.filter((x) => x.sl_id == this.purchaseOrderJson.inbound_carrier);
      this.disableSignature = CarrierFlags[0].sig_required;
      this.disableSatDel = CarrierFlags[0].sat_delivery;
    },
    // Submit API CALL
    async postPoSubmit() {
      if (this.$refs.CarrierLevlInfoForm.validate() && this.$refs.addressForm.validate()) {
        this.purchaseOrderJson["draft_id"] = this.temp_po_id;
        this.purchaseOrderJson.ordered_date = Utility.convertLocalToUTC(this.purchaseOrderJson.ordered_date);
        this.purchaseOrderJson.delivery_date = Utility.convertLocalToUTC(this.purchaseOrderJson?.delivery_date);
        let final_save = {
          UserId: this.userId,
          json: JSON.stringify(this.purchaseOrderJson),
        };
        this.disableSubmitBtn = false;
        let saveOrder = await commonPoService.postPoSubmitUpsert("post", final_save, true);
        this.purchaseOrderJson.ordered_date = this.purchaseOrderJson.ordered_date.substr(0, 10);
        this.purchaseOrderJson.delivery_date = this.purchaseOrderJson.delivery_date.substr(0, 10);
        if (saveOrder == 400) {
          this.disableSubmitBtn = false;
        } else {
          this.isEditing = false;
          this.$router.push({
            name: "purchaseOrderDetails",
            params: {
              purchaseOrderId: btoa(saveOrder?.Result[0].po_id.toString()),
            },
          });
        }
      }
    },
    // Vaidalte Address
    async validatePO(item) {
      if (item) {
        let resp = await commonPoService.validatePONumber("get", item, this.purchaseOrderJson.proj_id);
        if (resp[0]?.OrdNum) {
          this.disablePurchaseForm = false;
          this.disableSubmit = false;
        } else {
          this.disablePurchaseForm = true;
        }
      }
    },
    // Add Address
    //Getting the data back from Compoents based on val flag we will call the respsective APIs and but the address
    async addAddress(vendorObj, val) {
      if (val == "vendor") {
        {
          let obj = {
            CuJson: JSON.stringify(vendorObj),
            pass: 1, //Do not remove
          };
          let res = await commonPoService.postAddVendor("post", obj, true);
          this.purchaseOrderJson.vendorInfo[0] = JSON.parse(JSON.stringify(vendorObj));
          this.purchaseOrderJson.vendorInfo[0].vendor_id = res?.Result[0]?.Vend_Id; // DO not Remove
          this.venSearchKey = this.purchaseOrderJson.vendorInfo[0].company;
        }
        this.showAddressDialog = false;
        this.showVenAddress = true;
        this.addressDialogTitle = "";
      }
      // new ship is added
      else if (val == "remit") {
        let obj = {
          CuJson: JSON.stringify(vendorObj),
          pass: 2, // DO not Remove
        };
        let res = await commonPoService.postAddVendor("post", obj, true);
        this.purchaseOrderJson.remitInfo[0] = JSON.parse(JSON.stringify(vendorObj));
        this.purchaseOrderJson.remitInfo[0].vendor_id = res?.Result[0]?.Vend_Id;
        this.remitSearchKey = this.purchaseOrderJson.remitInfo[0].company;
        this.showAddressDialog = false;
        this.showRemitAddress = true;
        this.addressDialogTitle = "";
      } else if (val == "editRemit") {
        this.purchaseOrderJson.remitInfo[0] = JSON.parse(JSON.stringify(vendorObj));
        this.remitSearchKey = this.purchaseOrderJson.remitInfo[0].company;
        this.showAddressDialog = false;
        this.showRemitAddress = true;
        this.addressDialogTitle = "";
      }
    },
    //Common Delete Function
    commonDeleteFunction(item) {
      if (item == "deleteFlex") {
        if (this.showLineItem) {
          this.temp_order_line.flex_field = this.temp_order_line.flex_field.filter((x) => x.sequence_number !== this.sequence_number);
        } else {
          this.purchaseOrderJson.flex_field = this.purchaseOrderJson.flex_field.filter((x) => x.sequence_number !== this.sequence_number);
        }
        this.showUpdateLine = false;
        this.showFlexDialog = false;
      } else if (item == "deleteLineItem") {
        this.clearLineItem();
        this.purchaseOrderJson.order_line = this.purchaseOrderJson.order_line.filter((x) => x.line_number !== this.edit_line_num);
        this.showLineItem = false;
        this.totalRecords = this.purchaseOrderJson.order_line.length;
      }
      this.deleteDialog = false;
      this.flex_field_value = "";
      this.flex_field_Att = "";
      this.$refs?.flexForm?.resetValidation();
    },
    resetFunction() {
      this.showOrderedDate=false;
      this.showDeliveryDate=false;
      this.showTempShipByDate=false;
      this.showTempDeliveryDate=false;
      this.$nextTick(() => {
      this.showOrderedDate=true;
      this.showDeliveryDate=true;
      this.showTempShipByDate=true;
      this.showTempDeliveryDate=true;
      this.temp_order_line.receive_delivery_date=null
      this.temp_order_line.sh_ship_by_date=null
      this.purchaseOrderJson.delivery_date=null
      this.purchaseOrderJson.ordered_date=null      
    })
      this.stepElement = 1;
      this.purchaseOrderJson.step_number = 1;
      this.purchaseOrderJson.request_number = "";
      this.purchaseOrderJson.proj_id = "";
      this.disableProject = false;
      this.disableOrderType = true;
      this.receiveSearchKey = "";
      this.disableBusiness = false;
      this.disableAuto = true;
      this.remitSearchKey = "";
      (this.venSearchKey = ""), (this.purchaseOrderJson.bu_id = "");
      this.disableVendor = false;
      this.showVenAddress = false;
      this.disableEmitTo = false;
      this.disableReceiveAdd = false;
      this.disableEmitAdd = false;
      this.disablePurchaseForm = true;
      this.showEditVendor = false;
      this.projectName = "";
      this.businessName = "";
      this.sameAsVendorCheck = false;
      this.showEmitDDL = false;
      this.showReceivedDDL = false;
      this.showAddressDialog = false;
      this.showEditRemit = false;
      this.disableShippingSection = false;
      this.showFlexDialog = false;
      this.showLineDelete = false;
      this.showUpdateLine = false;
      this.deleteDialog = false;
      this.deleteFlexItem = false;
      this.disableNotes = false;
      this.deleteLine = false;
      this.showLineItem = false;
      this.disableLine = false;
      this.showPartRetDetails = false;
      this.showexpIcon = false;
      this.showLineUpdate = false;
      this.showPartsItem = false;
      this.disablePurchaseForm = false;
      this.clearLineItem();
      this.clearReceiveData();
      this.clearRemitData();
      this.clearVenAddressData();
      
      this.$refs?.projectForm?.resetValidation();
      this.$refs?.purchaseOrderForm?.reset();
      this.purchaseOrderJson.order_line = [];
      this.totalRecords = 0;
    },
    // common Draft
    async postTempPO(obj) {
      let obj_save = {
        UserId: this.userId,
        oJson: JSON.stringify(obj),
        po_id: this.temp_po_id,
        isDraft: 1, // Hard Coded DO NoT delete
        project: this.projectName,
        business: this.businessName,
      };
      let saveOrder = await commonPoService.postPoTempUpsert("post", obj_save, true);
      if (saveOrder) {
        this.temp_po_id = saveOrder.Result[0].Temp_po_id;
        this.disableDraftBtn = false;
      }
    },
    // Validation based of Line Type CHeck
    // Validation based of Line Type CHeck
    validateLineType() {
      let LineItemUsed = [...new Set(this.purchaseOrderJson.order_line.map((item) => item.order_line_type))];
      for (let i = 0; i < LineItemUsed.length; i++) {
        for (let j = 0; j < this.LineTypeList.length; j++) {
          if (this.LineTypeList[j].so_type_id == LineItemUsed[i]) {
            if (this.shipCount == 0) {
              this.shippingReq = this.LineTypeList[j].to_ship == 1 ? true : false;
              this.shipCount = 1;
            }
            if (this.returnCount == 0) {
              this.returnReq = this.LineTypeList[j].to_return == 1 ? true : false;
              this.returnCount = 1;
            }
          }
        }
      }
    },
  },
  components: {
    breadcrumbComp,
    addressComp,
    BackToTop,
  },
};
